<template>
  <div id="UserTasksBlockWrapper">
    <div class="UserTasksBlock">
      <div class="TasksHeader">
        <p class="UserTasksTitle">Избранные задачи</p>
        <div class="TasksCounter">
          <span>{{ getJobFollowCounter }}</span>
        </div>
        <CircleQuestionIcon
          @click.native="
            () => {
              showComponentDesc = !showComponentDesc;
              scrollToTop();
            }
          "
        />
      </div>
      <div class="TasksList">
        <div v-if="showComponentDesc" class="TasksTip">
          <span class="Head">Что такое список задач?</span>
          <span class="Text"
            >Здесь ты можешь увидеть задачи, которые ты добавил в избранное, а
            так же все задачи, которые у нас для тебя есть. <br /><br />Выбери
            любую задачу, если хочешь сменить текущее задание.</span
          >
        </div>
        <div
          class="lds-dual-ring"
          v-if="getJobFollowCounter > 0 && Jobs.length === 0"
        ></div>
        <div
          class="TaskCard noselect"
          v-for="(obj, indx) in getUserTasksList"
          :key="`${obj.Job.id}${indx}`"
        >
          <div class="TaskDifficult">
            <div class="Directions">
              <span
                v-for="(sect, sindx) in obj.Job.sections"
                :key="`sect_name-${sindx}`"
                >{{ sect.name }}</span
              >
            </div>
            <div class="Difficulties">
              <div
                class="DifficultFilled"
                v-for="x in getTaskDifficult(obj.Job.level)"
                :key="x"
              ></div>
              <div
                class="DifficultEmpty"
                v-for="x in 3 - getTaskDifficult(obj.Job.level)"
                :key="x"
              ></div>
            </div>
          </div>
          <div
            v-if="obj.Job.archieve"
            class="TaskTitle"
            style="font-size: 14px; color: #d4367d"
          >
            Архивная задача
          </div>
          <div class="TaskTitle">
            {{ obj.Job.name }}
          </div>
          <div class="TaskFollowStatus">
            <div class="TCFLeft">
              <div class="IconPeoples"></div>
              <span
                >{{ obj?.Job?.JobFollowers }}
                {{ correctTaskFollowersName(obj?.Job?.JobFollowers) }}</span
              >
            </div>

            <div
              @click="unfollowTask(obj?.Job?.id)"
              class="IconHeartFilled"
            ></div>
          </div>
          <div class="DividerLine"></div>
          <span class="ProjectText">Проект: {{ obj?.ProjName }} </span>
          <router-link
            v-if="!obj.Job.archieve"
            class="ProjectViewTasks"
            :to="
              `/content/project-tasks?type=${
                obj?.isNProject ? 'NProject' : 'DefaultProject'
              }&id=${obj?.ProjID}`
            "
            >Посмотреть список задач проекта
          </router-link>
        </div>
      </div>

      <router-link style="margin-top: 4px" to="/content/projects-list">
        <ButtonGradientBorder
          ButtonText="Я хочу выбрать другие задачи"
          style="width: 100%"
        />
      </router-link>
    </div>
  </div>
</template>

<style scoped>
.IconPeoples {
  position: relative;
  display: block;

  width: 16px;
  height: 12px;

  background: url("./../../assets/img/Landing/Peoples16x12.svg");
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
}

.IconHeart {
  position: relative;
  display: block;

  width: 20px;
  height: 18px;

  background: url("./../../assets/img/Landing/HeartPurple.svg");
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;

  filter: drop-shadow(0px 0px 4px #ffffff60);
}
.IconHeartFilled {
  position: relative;
  display: block;

  cursor: pointer;

  width: 20px;
  height: 18px;

  background: url("./../../assets/img/Landing/HeartPink.svg");
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
}

.TCFLeft > * {
  margin-top: auto;
  margin-bottom: auto;
}
.TCFLeft {
  position: relative;

  width: fit-content;
  height: fit-content;

  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  gap: 5px;
}
.TCFLeft > span {
  position: relative;
  display: block;

  width: fit-content;
  height: fit-content;

  color: #fff;

  /* tag */
  font-family: "Montserrat";
  font-size: 13px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.TaskCard > .TaskFollowStatus {
  position: relative;

  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 10px;

  margin-top: 10px;

  width: 100%;
  height: fit-content;
}

.Difficulties {
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;

  width: fit-content;
  height: fit-content;
}
.DifficultEmpty {
  position: relative;
  display: block;

  width: 16px;
  height: 16px;

  background: url("./../../assets/img/profile/ProfileProjectCard/DifficultIconPlaceholder.svg");
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
}
.DifficultFilled {
  position: relative;
  display: block;

  width: 16px;
  height: 16px;

  background: url("./../../assets/img/profile/ProfileProjectCard/DifficultIcon.svg");
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
}

.TaskCard > .TaskDifficult > * {
  margin-top: auto;
  margin-bottom: auto;
}
.TaskCard > .TaskDifficult {
  position: relative;

  width: 100%;
  height: fit-content;

  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  gap: 10px;
}
.TaskCard > .TaskDifficult > .Directions {
  position: relative;
  display: flex;
  gap: 5px;
}
.TaskCard > .TaskDifficult > .Directions > span {
  position: relative;
  display: block;

  width: fit-content;
  height: fit-content;

  color: #1d1a42;
  font-family: "Montserrat";
  font-size: 13px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;

  padding: 4px 8px;

  border-radius: 4px;
  background: #1dd185;
}

.TaskCard > .ProjectViewTasks {
  position: relative;
  display: block;

  width: fit-content;
  height: fit-content;

  cursor: pointer;

  font-family: "Montserrat";
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 155%;
  text-decoration-line: underline;

  background: linear-gradient(221deg, #c548ab -3.4%, #635bff 101.08%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.TaskCard > .ProjectText {
  color: #e7f6ff;
  font-family: "Montserrat";
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 155%;
}
.TaskCard > .TaskTitle {
  color: #fff;

  /* text medium */
  font-family: "Montserrat";
  font-size: 15px;
  font-style: normal;
  font-weight: 500;
  line-height: 155%; /* 23.25px */
}

.TaskCard > .DividerLine {
  position: relative;
  display: block;

  margin: 10px 0%;

  width: 100%;
  height: 1px;

  background: #292558;
}
.TaskCard {
  position: relative;

  margin-bottom: 15px;

  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  gap: 10px;

  padding: 20px 30px;

  border-radius: 20px;
  background: #1f1e48;
}

.TasksTip {
  position: relative;
  display: flex;

  flex-direction: column;
  justify-content: flex-start;
  gap: 10px;

  margin-bottom: 15px;

  width: 100%;
  height: fit-content;

  padding: 20px 30px;

  border-radius: 20px;
  background: #292558;
}
.TasksTip > .Head {
  position: relative;
  display: block;

  width: fit-content;
  height: fit-content;

  color: #fafafa;

  font-family: "Montserrat";
  font-size: 15px;
  font-style: normal;
  font-weight: 500;
  line-height: 155%; /* 23.25px */
}
.TasksTip > .Text {
  position: relative;
  display: block;

  width: fit-content;
  height: fit-content;

  color: #fafafa;

  color: #c8c3de;
  font-family: "Montserrat";
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.TasksHeader > * {
  margin-top: auto;
  margin-bottom: auto;
}
.TasksHeader > .TasksCounter > span {
  position: relative;
  display: block;

  margin: auto;

  width: fit-content;
  height: fit-content;

  color: #e8e7ec;
  text-align: center;
  font-family: "Montserrat";
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}
.TasksHeader > .TasksCounter {
  position: relative;
  display: flex;

  width: 24px;
  height: 24px;

  color: #e8e7ec;
  text-align: center;
  font-family: "Montserrat";
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;

  border-radius: 100%;
  background: linear-gradient(91deg, #1e768e 2.08%, #9652d4 98.48%);

  flex-shrink: 0;
}
.TasksHeader {
  position: relative;

  width: 100%;
  height: fit-content;

  margin-bottom: 20px;

  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  gap: 5px;

  flex-shrink: 0;
}

#UserTasksBlockWrapper {
  position: relative;
  width: 100%;
  height: 100%;

  padding: 30px;

  overflow-y: auto;

  background: rgba(32, 30, 72, 0.7);

  /* shadow */
  box-shadow: 8px 0px 8px rgba(31, 18, 35, 0.15);
  border-radius: var(--o-s-global-border-radius);
}
#UserTasksBlockWrapper > .UserTasksBlock {
  position: relative;

  width: 100%;
  height: 100%;

  display: flex;
  flex-direction: column;
}

.UserTasksBlock > .TasksHeader > .UserTasksTitle {
  position: relative;

  font-family: "Montserrat";
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 24px;

  /* students/white */
  color: #e8e7ec;
}
.UserTasksBlock > .TasksList {
  position: relative;

  display: flex;
  flex-direction: column;

  width: 100%;
  height: 100%;

  padding-right: 14px;

  filter: drop-shadow(00px 00px 4px #00000036);

  overflow-y: auto;
}
.UserTasksBlock > .TasksFooterText {
  position: relative;
  width: auto;

  cursor: pointer;

  margin: 16px auto 0% 0%;
  padding-bottom: 1px;

  border-bottom: 2px solid rgba(232, 231, 236, 0.15);

  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 18px;

  /* students/white */
  color: #c4c4c4;
}

.TaskBlock {
  position: relative;
  width: 100%;
  height: auto;
}

.TaskBlock > .TasksBlockText,
.Zaglushka {
  margin-bottom: 32px;

  height: fit-content;

  /* text medium */
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  line-height: 155%;

  /* or 23px */

  /* students/white */
  color: #e8e7ec;
}

.TasksList > .TaskBlock:not(:nth-child(1)) {
  margin-top: 16px;
}
.TasksList > .TaskBlock:not(:nth-last-child(1)) {
  border-bottom: 1px solid rgba(232, 231, 236, 0.2);
}

.ComboSpanLeftAlign {
  position: relative;
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
}
.ComboSpanLeftAlign > * {
  margin-top: auto;
  margin-bottom: auto;
}

.ComboSpanLeftAlign > *:nth-child(1) {
  margin-right: 8px;
}

.TaskStatus {
  position: relative;
  width: 16px;
  height: 16px;
}

.TaskStatusText {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 155%;

  /* or 23px */

  /* students/white */
  color: #e8e7ec;
}

.TaskHeader {
  margin-bottom: 8px;
}

.StatusReady {
  background-image: url("./../../assets/img/profile/ProfileTaskIcons/TaskDone.svg");
  background-repeat: no-repeat;
  background-size: 16px;
}
.StatusFail {
  background-image: url("./../../assets/img/profile/ProfileTaskIcons/TaskFail.svg");
  background-repeat: no-repeat;
  background-size: 16px;
}

.TaskBlockDate {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 155%;

  /* or 23px */

  /* students/white 2 */
  color: #c8c3de;
}

.TaskBadge {
  margin-right: 8px;

  padding: 4px 8px;

  width: 156px;
  height: 24px;

  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  line-height: 16px;

  /* identical to box height */

  /* students/bg 2 */
  color: #201e48;
  background: #1d9bd1;
  border-radius: 4px;
}

.TaskFooter {
  margin-bottom: 16px;
  flex-wrap: wrap;
  row-gap: 10px;
}
</style>

<style scoped>
@media (max-width: 480px) {
  #UserTasksBlockWrapper {
    padding: 19px 10px 19px 16px;
  }

  .UserTasksBlock > .UserTasksTitle {
    margin-bottom: 16px;
    font-size: 16px;
    line-height: 120%;

    /* students/white */
    color: #e8e7ec;
  }

  .UserTasksBlock > .TasksList {
    padding-right: 6px;
  }

  .UserTasksBlock > .TasksFooterText {
    margin: 16px auto 0% 0%;
    padding-bottom: 1px;
    font-size: 12px;
    line-height: 110%;
  }

  .TaskBlock > .TasksBlockText {
    margin-bottom: 32px;
    font-size: 12px;
    line-height: 155%;
  }

  .TaskStatus {
    width: 13px;
    height: 13px;

    margin-right: 4px !important;
  }
  .TaskStatusText {
    font-size: 12px;
    line-height: 155%;
  }

  .StatusReady {
    background-size: 12px;
  }
  .StatusFail {
    background-size: 12px;
  }

  .TaskBlockDate {
    font-size: 11px;
    line-height: 155%;
  }

  .TaskBadge {
    margin-right: 8px;

    padding: 4px 8px;

    width: fit-content !important;
    height: fit-content !important;

    font-size: 11px;
    line-height: 110%;
  }
}
@media (min-width: 480px) and (max-width: 768px) {
}
@media (min-width: 768px) and (max-width: 992px) {
}
@media (min-width: 992px) and (max-width: 1200px) {
  #UserTasksBlockWrapper {
    min-height: 500px;
    max-height: 100%;
  }
}
@media (min-width: 1200px) and (max-width: 1600px) {
  #UserTasksBlockWrapper {
    min-height: 500px;
    max-height: 100%;
  }
}
</style>

<script>
import ButtonGradientBorder from "@/components/CustomElements/ButtonGradientBorder.vue";
import CircleQuestionIcon from "@/components/CustomElements/CircleQuestionIcon.vue";

import {
  getAllDifficulties,
  addFollowersJob,
  removeFollowersJob,
  findUserJobsWithProjects,
  getLatestFollowersJob,
} from "@/api/job.js";
import { mapGetters, mapMutations } from "vuex";
import { findById } from "@/api/user.js";

export default {
  props: {
    UseExistedDifficults: {
      type: Boolean,
      default: () => {
        return false;
      },
    },
    ExistedDifficults: {
      type: Array,
      default: () => {
        return [];
      },
    },
  },
  name: "TasksList",
  data() {
    return {
      showComponentDesc: false,
      TasksList: [],
      isModalVisible: false,

      ProjectsList: [],
      Difficulties: [],
      Jobs: [],
    };
  },
  watch: {
    user: {
      handler(val) {
        if (this.id) {
          this.id = this.user.id;
        }
      },
      deep: true,
    },
  },
  async created() {
    await findUserJobsWithProjects(this?.user?.id).then((res) => {
      if (res?.data?.Jobs != null && Array.isArray(res?.data?.Jobs)) {
        this.Jobs = res.data.Jobs.map((x) => {
          return { ...x, subsListModified: [] };
        });
      }
    });
    if (!this.UseExistedDifficults) {
      await getAllDifficulties().then((response) => {
        this.Difficulties = response.data;
      });
    } else {
      this.Difficulties = this.ExistedDifficults;
    }
  },
  components: { ButtonGradientBorder, CircleQuestionIcon },
  computed: {
    ...mapGetters({
      user: "auth/isLoggedIn",
    }),

    getJobFollowCounter() {
      return `${this?.user?.Jobs.length}`;
    },

    getUserTasksList() {
      let JobsWithProjects = [];
      this.Jobs.forEach((job) => {
        let isNProject = job?.project == null ? true : false;

        JobsWithProjects.push({
          Job: { ...job, JobFollowers: job.JobFollowers.length },
          isNProject: isNProject,
          ProjID: isNProject ? job?.n_project?.id : job?.project?.id,
          ProjName: isNProject ? job?.n_project?.name : job?.project?.name,
        });
      });

      return JobsWithProjects;
    },
  },
  methods: {
    ...mapMutations("auth", ["SET_USER"]),

    scrollToTop() {
      // Находим элемент якорного блока
      const scrollToMe = document.querySelector(".TasksList");

      // Пролистываем страницу до якорного блока
      setTimeout(
        () => {
          scrollToMe.scrollTo({
            top: 0,
            behavior: "smooth", // Добавляем плавную анимацию скролла
          });
        },
        this.showComponentDesc ? 0 : 200
      );
    },

    correctTaskFollowersName(tasksCounter) {
      if (tasksCounter % 10 == 1 && tasksCounter != 11) {
        return "следит";
      }

      return "следят";
    },

    getTaskDifficult(DiffName) {
      let indx = this.Difficulties.findIndex((obj) => {
        return obj.name === DiffName;
      });

      return indx + 1;
    },

    async userFollowThisTask(TaskID) {
      await this.updateTaskFollowers(TaskID);

      let Task = this.Jobs.find((obj) => {
        return obj.id === TaskID;
      });

      if (Task == null) {
        return 0;
      }

      let FindFollow = Task.subsListModified.find((user) => {
        return String(user.id) === String(this.user.id);
      });

      if (FindFollow == null) {
        return false;
      } else {
        return true;
      }
    },
    async updateTaskFollowers(taskID) {
      let subs = await this.getTaskFollowers(taskID);
      let Task = this.Jobs.find((obj) => {
        return obj.id === taskID;
      });
      Task.subsListModified = subs.data.JobFollowers;
    },
    async clickTaskHeart(taskID) {
      if (this.userFollowThisTask(taskID)) {
        await this.unfollowTask(taskID);
      } else {
        await this.followTask(taskID);
      }

      await this.updateTaskFollowers(taskID);
    },
    async followTask(taskID) {
      let temp = [];
      temp.push(this.user.id);
      await addFollowersJob(taskID, {
        followers: temp,
      });
      findById(this.user.id).then((res) => this.SET_USER(res.data));
    },
    async unfollowTask(taskID) {
      let temp = [];
      temp.push(this.user.id);
      await removeFollowersJob(taskID, {
        followers: temp,
      });
      findById(this.user.id).then((res) => this.SET_USER(res.data));

      let JobIndex = this.Jobs.findIndex((x) => x.id === taskID);
      if (JobIndex != -1) {
        this.Jobs.splice(JobIndex, 1);
      }
    },
    async getTaskFollowers(taskID) {
      return await getLatestFollowersJob(taskID);
    },
  },
};
</script>
